<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row class="mt-1">
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="blue-text text-darken-3 text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row class="mt-1">
            <v-col cols="12" xs="12" sm="12" md="12">
                <v-card outlined>  
                    <v-card-text>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <v-card class="rounded-l mr-5 green lighten-5" outlined>
                                    <v-card-text class="pl-4 pr-2 p-1">
                                        <span class="mb-1 pb-1">Total Price</span>
                                        <h5 style="font-weight:600;" class="mt-0 pt-0 green--text">
                                            {{$store.getters.convertToCurrencyNoFrac(this.total_price)}}
                                        </h5>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-card class="rounded-l mr-5 blue lighten-5" outlined>
                                    <v-card-text class="pl-4 pr-2 p-1">
                                        <span class="mb-1 pb-1">Total Weight</span>
                                        <h5 style="font-weight:600;" class="mt-0 pt-0 primary--text">
                                            {{$store.getters.convertToCurrencyUs(this.total_weight)}}
                                        </h5>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col class="col-12" sm="12" md="8">
                                <div class="d-flex">
                                    <v-spacer />
                                    <v-autocomplete dense solo class="ma-2" hide-details=true v-model="commodity"
                                        :items="commodities" default="" label="Commodity" style="max-width: 200px;" @change="(event) => getProdSpec(event)">
                                    </v-autocomplete>
                                    <v-autocomplete dense solo class="ma-2" hide-details=true v-model="product" item-value="prod_code"
                                    :item-text="item => item.descr.trim()" :items="products" default="" label="Product" style="max-width: 300px;" :loading="loading_prod">
                                    </v-autocomplete>
                                    <v-menu
                                        ref="modal"
                                        v-model="modal"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            dense
                                            solo
                                            v-model="dateRangeText"
                                            label="Date Range"
                                            persistent-hint
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-1 pa-1 mr-1"
                                            hide-details=true
                                            style="max-width: 200px;"
                                            clearable
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            range
                                            v-model="dates"
                                            no-title
                                            @input="modal = dates.length > 1 ? false : true"
                                        ></v-date-picker>
                                    </v-menu>
                                    <v-btn
                                    small
                                    color="#fff"
                                    class="py-5 mr-1 ma-2"
                                    @click="clear()"
                                    >
                                        <v-icon>mdi-backspace</v-icon>
                                    </v-btn>
                                    <v-btn
                                    small
                                    color="#fff"
                                    class="py-5 mr-3 ma-2"
                                    @click="getPullData()"
                                    >
                                        <v-icon class="mr-2">mdi-magnify</v-icon> Search
                                    </v-btn>
                                </div>
                            </v-col>
                            
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12">
                <v-card outlined>
                    <v-data-table 
                    fixed-header
                    dense
                    height="475"
                    :headers="headers" 
                    :items="aluminium_usages" 
                    class="elevation-0" 
                    :item-class="tr_datatable"
                    :items-per-page="30"
                    :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                    }"
                    :search="search" 
                    :loading="loading">
                        <template v-slot:top>
                            <v-toolbar flat color="white" class="mt-2 mb-2">
                                <div class="d-flex w-100">
                                    <h6 class="blue-text text-darken-3 m-0 mr-3">Result</h6>
                                    
                                    <v-spacer></v-spacer>
                                    <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="search" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    <v-btn
                                    small
                                    color="#005c37"
                                    class="py-5 text-white border-12"
                                    >
                                        <v-icon>mdi-file-excel</v-icon>
                                        <download-excel
                                            class="text"
                                            :fetch           = "exportExcel"
                                            :fields="headersColumn"
                                            :before-generate = "startDownload"
                                            :before-finish   = "finishDownload">
                                            Export Excel
                                        </download-excel>
                                    </v-btn>
                                </div>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.tr_date`]="{ item }">
                            {{ item.tr_date.substr(0, 10) }}
                        </template>
                        <template v-slot:[`item.unit_price`]="{ item }">
                            {{ $store.getters.convertToCurrencyNoFrac(Number(item.unit_price) * Number(item.rate) * Number(item.wgt)) }}                            
                        </template>
                        <template v-slot:[`item.wgt`]="{ item }">
                            {{ $store.getters.convertToCurrency(item.wgt) }}
                        </template>
                        <template v-slot:[`item.unit_conv`]="{ item }">
                            {{ $store.getters.convertToCurrency(item.unit_conv) }}
                        </template>
                        <template v-slot:[`item.rate`]="{ item }">
                            {{ $store.getters.convertToCurrency(item.rate) }}
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Production',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Aluminium Zinc Receive',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            loading: false,
            search: '',
            order_id: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            headers: [
                { text: 'TR ID', value: 'tr_id' },
                { text: 'TR Date', value: 'tr_date' },
                { text: 'Product ID', value: 'coil_id' },
                { text: 'ProdCode', value: 'prod_code' },
                { text: 'Descr', value: 'descr' },
                // { text: 'Currency', value: 'curr_id' },
                // { text: 'Rate', value: 'rate', align: 'right' },
                { text: 'Price', value: 'unit_price', align: 'right' },
                { text: 'Weight', value: 'wgt', align: 'right' },
                { text: 'Unit Conv', value: 'unit_conv', align: 'right' },
                { text: 'Meas', value: 'unit_meas'},
                { text: 'Vendor', value: 'vendor_name' },
                // { text: 'Action', value: 'action' }
            ],
            headersColumn:{
                'TR ID': 'tr_id',
                'TR Date': 'tr_date',
                'Coil ID': 'coil_id',
                'ProdCode': 'prod_code',
                'Descr': 'descr',
                'Currency': 'curr_id',
                'Rate': 'rate',
                'Price': 'unit_price',
                'Weight': 'wgt',
                'Unit Conv': 'unit_conv',
                'Unit Meas': 'unit_meas',
                'Vendor': 'vendor_name'
            },
            aluminium_usages: [],
            commodities: [
                'ALM', 'ZN', 'CHEM'
            ],
            commodity: '',
            dates: [],
            products: [],
            product: '',
            loading_prod: false
            // total_price: '',
            // total_weight: ''
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        this.commodity = this.commodities[0]
        await this.getProdSpec(this.commodity)
        this.$store.dispatch('setOverlay', false)
    },
    computed:{
        total_price(){
            var total_price = 0
            for (let index = 0; index < this.aluminium_usages.length; index++) {
                total_price = total_price + (Number(this.aluminium_usages[index].unit_price) * Number(this.aluminium_usages[index].rate) * Number(this.aluminium_usages[index].wgt));
            }
            
            return (total_price)
        },
        total_weight(){
            var total_weight = 0
            for (let index = 0; index < this.aluminium_usages.length; index++) {
                total_weight = total_weight + Number(this.aluminium_usages[index].wgt);
            }
            
            return (total_weight)
        },
        dateRangeText () {
            return this.dates.join(' ~ ')
        },
    },
    methods:{
        clear(){
            this.dates = []
        },
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        async getProdSpec(event){
            if (event == '' || event == null) {
                this.commodity = 'ALM'
            } else {
                this.loading_prod = true
                const respData = await backendApi.fetchCore(`/v2/master/sr/prod_spec?commodity_id=${this.commodity ? this.commodity : ""}`, '', false, false, false)

                if (respData.status === 200) {
                    this.loading_prod = false
                    this.products = respData.data
                }   
            }
        },
        async summary(){
            var start_date = this.dates.length > 1 ? new Date(this.dates[0]).toISOString().substr(0, 10) : ''
            var end_date = this.dates.length > 1 ? new Date(this.dates[1]).toISOString().substr(0, 10) : ''

            var reqBody = {
                'txtCommodity': this.commodity,
                'txtStart': start_date ? start_date : "",
                'txtEnd': end_date ? end_date : ""
            }

            const respData = await backendApi.fetchCore('/v2/sr/aluminium-zinc-receive/summary', reqBody, false, false, false)
            if (respData.status === 200) {
                this.total_price = Number(respData.data.total_unit_price)
                this.total_weight = Number(respData.data.total_wgt)
            }
        },
        async getPullData(){
            this.aluminium_usages = []

            var start_date = this.dates.length > 1 ? new Date(this.dates[0]).toISOString().substr(0, 10) : ''
            var end_date = this.dates.length > 1 ? new Date(this.dates[1]).toISOString().substr(0, 10) : ''

            this.$store.dispatch('setOverlay', true)

            // await this.summary()

            const respData = await backendApi.fetchCore(`/v2/sr/aluminium-zinc-receive?txtStart=${start_date ? start_date : ""}&txtEnd=${end_date ? end_date : ""}&txtCommodity=${this.commodity ? this.commodity : ""}&prod_code=${this.product ? this.product : ""}`, '', false, false, false)

            if (respData.status === 200) {
                this.aluminium_usages = respData.data
                this.$store.dispatch('setOverlay', false)
            }
        },
        async exportExcel(){
            var start_date = this.dates.length > 1 ? new Date(this.dates[0]).toISOString().substr(0, 10) : ''
            var end_date = this.dates.length > 1 ? new Date(this.dates[1]).toISOString().substr(0, 10) : ''

            const respData = await backendApi.fetchCore(`/v2/sr/aluminium-zinc-receive?txtStart=${start_date ? start_date : ""}&txtEnd=${end_date ? end_date : ""}&txtCommodity=${this.commodity ? this.commodity : ""}`, '', false, false, false)

            if (respData.status === 200) {
                return respData.data
            }
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    },
    watch: {
    },
}
</script>
<style>
    .tr_datatable{
        background-color: #F5F7F8 !important;
    }
</style>